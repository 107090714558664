import React from "react";
import { bookACallIFrame, getPathname, Hero } from "@italymondo/core-ui";
import { graphql } from "gatsby";
import { Layout } from "../../components";
import { ConfirmationTemplateQuery } from "./index.generated";
import { Container, StyledLink } from "./styles";

type PpConfirmationPage = {
  data: ConfirmationTemplateQuery;
};

export default function ConfirmationPage({
  data: {
    cms: { site },
  },
}: PpConfirmationPage): React.ReactElement | null {
  if (!site?.page) {
    return <Layout />;
  }

  const { ctaLink, ctaTitle, description, headline } = site.page;

  return (
    <Layout>
      <Hero description={description || ""} headline={headline || ""} />
      <Container>
        {bookACallIFrame}
        {ctaLink && ctaTitle && (
          <StyledLink
            highlighted
            color="primary"
            to={getPathname(ctaLink.url)}
            variant="caption"
          >
            {ctaTitle}
          </StyledLink>
        )}
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query ConfirmationTemplate($slug: String) {
    cms {
      site(hostname: "www.italymondo.com") {
        page(slug: $slug) {
          ... on CMS_IMConfirmationPage {
            ctaLink {
              url
            }
            ctaTitle
            description
            headline
          }
        }
      }
    }
  }
`;
