import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Link, SectionContainer } from "@italymondo/core-ui";

export const Container = styled(SectionContainer)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.colStretch};
  `}
`;

export const StyledLink = styled(Link)`
  margin: 0 auto;
`;
