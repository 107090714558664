import React from "react";
import { Helmet } from "react-helmet";

export const bookACallIFrame = (
  <React.Fragment>
    <Helmet>
      <script
        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        type="text/javascript"
      />
    </Helmet>
    <div
      className="meetings-iframe-container"
      data-src="https://meetings.hubspot.com/italymondo/discovery?embed=true"
    />
  </React.Fragment>
);
